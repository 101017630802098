var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-icon', {
    style: "font-size: ".concat(_vm.size, "px !important"),
    attrs: {
      "x-small": _vm.xSmall,
      "small": _vm.small,
      "large": _vm.large,
      "x-large": _vm.xLarge,
      "color": _vm.color
    }
  }, [_vm._v(" " + _vm._s(_vm.android ? "android" : "mdi-apple") + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }