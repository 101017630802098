<template>
  <v-icon
    :x-small="xSmall"
    :small="small"
    :large="large"
    :x-large="xLarge"
    :style="`font-size: ${size}px !important`"
    :color="color"
  >
    {{ android ? "android" : "mdi-apple" }}
  </v-icon>
</template>
<script>
export default {
  props: {
    android: Boolean,
    apple: Boolean,
    color: String,
    xSmall: Boolean,
    small: Boolean,
    large: Boolean,
    xLarge: Boolean,
    size: [String, Number],
  },
};
</script>
